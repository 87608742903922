<template>
  <div class="account-settings-info-view" style="width:80%; margin:0 auto;">
    <a-tabs default-active-key="1" :tab-position="tabPosition" style="background-color: #ffffff;padding: 60px; height: 70vh">
      <a-tab-pane key="1" tab="头像密码设置">
        <a-row :gutter="16">
          <a-col :md="24" :lg="16">

            <a-form :form="form" style="padding: 60px">
              <a-row :gutter="[16,16]">
                <a-col :span="12">
                  <a-form-item label="旧密码">
                    <a-input-password v-decorator="['origin_pwd', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
                  </a-form-item>
                  <a-form-item label="新密码">
                    <a-input-password v-decorator="['new_pwd', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
                  </a-form-item>
                  <a-form-item label="确认新密码">
                    <a-input-password v-decorator="['enter_new_pwd', {rules: [{required: true, validator: validatePass2, trigger: 'blur'}]}]" />
                    <div class="ant-form-explain" v-if="!!tip">  {{ tip }} </div>
                  </a-form-item>
                  <a-form-item>
                    <a-button type="primary" @click="changePassWord" v-action:edit> 修改密码</a-button>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form>
          </a-col>
          <a-col :md="24" :lg="8" :style="{ minHeight: '180px' }">
            <div class="ant-upload-preview" @click="openAvatar" >
              <a-icon type="cloud-upload-o" class="upload-icon"/>
              <div class="mask">
                <a-icon type="plus" />
                修改头像
              </div>
              <img :src="option.img || 'https://static.jnzbtz.com/default_avatar.png'"/>
            </div>
          </a-col>
        </a-row>
      </a-tab-pane>
      <a-tab-pane key="2" tab="消息通知设置">
        <a-form :form="configForm" labelAlign="left" @submit="handleConfigOk" style="padding: 60px">
          <a-row :gutter="[16,16]">
            <a-col :span="12">
              <a-form-item label="保质期提醒">
                <a-switch v-decorator="['shelf_life_notice', { valuePropName: 'checked' }]" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="[16,16]">
            <a-col>
              <a-form-item>
                <a-button type="primary" html-type="submit">
                  确定
                </a-button>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-tab-pane>
    </a-tabs>

    <avatar-modal
      ref="modal"
      @ok="setavatar"
      @cancel="handleAddCancel"
      v-if="okvisible"
      :visible="okvisible"
    />

  </div>
</template>

<script>
import AvatarModal from './AvatarModal'
import pick from 'lodash.pick'
import { user_change_password_update, user_list, user_update } from '@/api/user'
import storage from 'store'
import { User_Id } from '@/store/mutation-types'
import { user_notice_create, user_notice_list } from '@/api/user_notice'
const fields = ['origin_pwd', 'new_pwd', 'enter_new_pwd', 'shelf_life_notice']
export default {
  name: 'BaseSettings',
  components: {
    AvatarModal
  },
  data () {
    return {
      configForm: this.$form.createForm(this),
      configVisible: false,
      configLoading: false,
      tabPosition: 'left',
      okvisible: false,
      tip: '',
      userId: '',
      origin_pwd: '',
      new_pwd: '',
      enter_new_pwd: '',
      form: this.$form.createForm(this),
      // cropper
      preview: {},
      mdl: {},
      option: {
        img: '',
        info: true,
        size: 1,
        outputType: 'jpeg',
        canScale: false,
        autoCrop: true,
        // 只有自动截图开启 宽度高度才生效
        autoCropWidth: 180,
        autoCropHeight: 180,
        fixedBox: true,
        // 开启宽度和高度比例
        fixed: true,
        fixedNumber: [1, 1]
      }
    }
  },
  created () {
    this.userId = storage.get(User_Id)
    fields.forEach(v => this.form.getFieldDecorator(v))
    fields.forEach(v => this.configForm.getFieldDecorator(v))
    this.getuserlist()
    this.get(this.userId)
  },
  methods: {
    handleConfigOk (e) {
      const form = this.configForm
      this.configLoading = true
      e.preventDefault()
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          values.user_id = this.userId
          // 新增
          user_notice_create(values).then(res => {
            console.log(res)
          }).finally(() => {
            console.log(11)
          })
        } else {
          console.log('2')
        }
      })
    },
    // 获取消息通知列表
    get (id) {
      user_notice_list({ user_id: id }).then(({ data: { entries } }) => {
        const { configForm } = this
        const formData = pick(entries[0], fields)
        configForm.setFieldsValue(formData)
      })
    },
    getuserlist () {
      user_list({ id: this.userId }).then(({ data }) => {
        this.option.img = data.entries[0].avatar
      })
    },
    handleAddCancel () {
      this.okvisible = false
    },
    openAvatar () {
      this.okvisible = true
      this.mdl = {}
    },
    validatePass2 (rule, value, callback) {
      console.log(value, '我是确认密码', this.form.getFieldValue('new_pwd'))
      const new_pwd = this.form.getFieldValue('new_pwd')
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== new_pwd) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    },
    setavatar (obj) {
      console.log(obj.url, '我是图片')
      this.option.img = obj.url
      this.okvisible = false
      user_update({ avatar: obj.url }, this.userId).then((res) => {
        console.log(res)
      })
    },
    changePassWord () {
        const form = this.form
        form.validateFields((errors, values) => {
          if (!errors) {
              console.log('values', values)
              const userId = storage.get(User_Id)
            user_change_password_update(values, userId).then((res) => {
                console.log(res, '我是重置密码')
                if (res.success) {
                  this.$message.success('成功')
                } else {
                  this.$message.warning('重置失败')
                }
              })
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>

  .avatar-upload-wrapper {
    height: 200px;
    width: 100%;
  }

  .ant-upload-preview {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 180px;
    border-radius: 50%;
    box-shadow: 0 0 4px #ccc;

    .upload-icon {
      position: absolute;
      top: 0;
      right: 10px;
      font-size: 1.4rem;
      padding: 0.5rem;
      background: rgba(222, 221, 221, 0.7);
      border-radius: 50%;
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
    .mask {
      opacity: 0;
      position: absolute;
      background: rgba(0,0,0,0.4);
      cursor: pointer;
      transition: opacity 0.4s;

      &:hover {
        opacity: 1;
      }

      i {
        font-size: 2rem;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -1rem;
        margin-top: -1rem;
        color: #d6d6d6;
      }
    }

    img, .mask {
      width: 100%;
      max-width: 180px;
      height: 100%;
      border-radius: 50%;
      overflow: hidden;
    }
  }
</style>
